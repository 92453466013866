

























































































































































import Vue from 'vue';
import { Action, State, namespace } from 'vuex-class';
import { Component, Prop } from 'vue-property-decorator';
import { Block, Type } from '@/services/api';
import { Query, FilterStatus } from '@/store/modules/types/Query';
import orderby from 'lodash/orderBy';
import { DataTableHeader } from 'vuetify';
import { KSelect, KScrollBtn } from '@kasasa/fbase-components';
import { searchHighlight } from '@kasasa/fbase-components/lib'; 
import { RouteName } from '@/router';

import KCustomSelect from './KCustomSelect.vue';

const reverseSort = (a: number, b: number): number => (a > b ? -1 : 1);

const types = namespace('types');

@Component({
	components: {
		KCustomSelect,
		KSelect,
		KScrollBtn
	},
})
export default class BlockListTable extends Vue {
	@Prop() items!: Block[];
	@Prop({ default: false }) readonly isGlobal !: boolean;
	@Action('resetQuery') resetQuery!: () => void;
	@State('query') query!: Query;

	@types.Action('fetchTypes') fetchTypes!: () => Promise<Type[]>;
	@types.State('types') types !: Type[];
	

	distinct( value: string, index: number, self: string[] ): boolean {
		return self.indexOf(value) === index;
	}

	get scope(): string {		
		return this.isGlobal ? 'global': 'site';
	}

	get typeOptions(): string[] {
		return this.types.filter( el => el.scope === this.scope).map( el => el.name);
	}

	get subTypeOptions(): string[] {
		return this.items.filter( el => el.type.scope === this.scope).map( ol => ol.subType.name).filter(this.distinct);
	}
	
	created(): void {
		if (this.query.siteId != this.$route.params.siteId) this.resetQuery();
		this.query.siteId = this.$route.params.siteId;
		this.fetchTypes();
	}

	FiltersSwitch(): void {
		if (this.query.useFilter) {
			this.resetFilter();
		}
	}

	get displayBlocks(): Block[]  {
		const lower = (block: Block) => {
			return block.name ? block.name.toLowerCase() : block.name;
		};
		if(!this.query.useFilter) return orderby(this.items, [lower]);
		return orderby(this.items.filter((block: Block) => this.typeFilter(block) && this.subTypeFilter(block) && this.statusFilter(block)), [lower]);
	}

	typeFilter(block: Block): boolean {
		return !this.query.selectedType.length || this.query.selectedType.includes(block.type.name);
	}

	subTypeFilter(block: Block): boolean {
		return !this.query.selectedSubType.length || this.query.selectedSubType.includes(block.subType.name);
	}

	statusFilter(block: Block): boolean {
		return this.query.selectedStatus === null
			|| this.query.selectedStatus === FilterStatus.All
			|| block.isDefault === (this.query.selectedStatus === FilterStatus.Default);
	}

	resetFilter(): void {
		this.query.selectedType = [];
		this.query.selectedSubType = [];
		this.query.selectedStatus = FilterStatus.All;
	}
	
	get headers(): DataTableHeader[] {
		const dataheaders: DataTableHeader[] = [			
			{ text: 'Type', value: 'type.name',    width: '150px'},
			{ text: 'Name', value: 'name',    width: '280px' },
			{ text: 'Subtype', value: 'subType.name',    width: this.isGlobal? '450px' : '150px'},
		];
		if (!this.isGlobal) {
			dataheaders.push({ text: 'Block Status', value: 'isDefault', width: '300px', sort: reverseSort });
		}
		return dataheaders;
	}

	get filterStatusValues(): FilterStatus[] {
		return [FilterStatus.All, FilterStatus.Default, FilterStatus.NotDefault];
	}

	searchHighlight(value: string | number, querySearch: string): string | number {
		return searchHighlight(value, querySearch);
	}

	handleClick(row: Block): void {
		if (!row.id) {
			return;
		}
		if (this.isGlobal) {
			this.$router.push({name: RouteName.GLOBAL_EDIT, params: { blockId: row.id.toString() }});
		} else {
			this.$router.push({name: RouteName.BLOCK_FORM, params: { clientId: this.$route.params.clientId, siteId: this.$route.params.siteId, blockId: row.id.toString() }});
		}
	}
	
	customFilter(index: number, search: string, item: Block): boolean | string {
		this.query.page = 1;
		const lSearch = search.toLowerCase();
		return (item.name && item.name.toLowerCase().indexOf(lSearch) !== -1);
	}

}
