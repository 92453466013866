import {Action, Module, Mutation, VuexModule} from 'vuex-module-decorators';
import {Block, excludeContentBlocksId, layoutNodesId, libraryCategories, LibraryItem, SubType} from '@/services/api';
import BlockService from '@/services/BlockService';
import TypeService from '@/services/TypeService';


@Module({
	namespaced: true,
})
export default class LibraryStore extends VuexModule {

	//List of all the blocks
	globalBlocks: Block[] = [];

	//global Blocks listed on each category (sub types)
	library: LibraryItem[] = [];

	//Selected global blocks for layout
	layoutNodes: LibraryItem[] = [];


	subTypes: SubType[] = [];

	loadingBlocks = false;

	@Mutation
	setLibrary(payload: Block[]): void {
		this.globalBlocks = payload;


		//Global Library and Global Bones
		this.library = [];
		this.subTypes.forEach((e) => {
			this.library.push({
				'name': e.id == 'default' ? libraryCategories.global : e.name,
				'blockList': this.globalBlocks.filter(el => (el.subType.id === e.id) && !excludeContentBlocksId.includes(el.id ? el.id : '')),
			});
		});

		//layoutNodes Blocks
		this.layoutNodes = [];
		this.layoutNodes.push({
			'name': libraryCategories.layout,
			'blockList': this.globalBlocks.filter(el => layoutNodesId.includes(el.id ? el.id : '')),
		});
	}

	@Mutation
	setLoadingBlocks(payload: boolean): void {
		this.loadingBlocks = payload;
	}

	@Mutation
	setSubTypes(payload: SubType[]): void {
		this.subTypes = payload;
	}

	@Action({commit: 'setLibrary'})
	async fetchLibrary(forceReload = false): Promise<Block[]> {

		if (this.loadingBlocks) {
			return this.globalBlocks;
		}

		if (!forceReload && this.globalBlocks && this.globalBlocks.length > 0) {
			return this.globalBlocks;
		}

		const typeSvc = new TypeService;
		const blockSvc = new BlockService;
		this.context.commit('setLoadingBlocks', true);

		return await typeSvc.getSubType().then(async (resp) => {
			this.context.commit('setSubTypes', resp.data.data);
			return await blockSvc.getGlobalBlocks()
				.then(resp => {

					this.context.commit('setLoadingBlocks', false);
					return resp.data.data || [];
				})
				.finally(() => {

					this.context.commit('setLoadingBlocks', false);
					return [];
				});
		});

	}
}