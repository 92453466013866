





























































































import { Component, Watch, Prop } from 'vue-property-decorator';
import Vue from 'vue';
import { RawLocation } from 'vue-router';

import { required } from 'vuelidate/lib/validators';
import { RuleDecl } from 'vue/types/options';
import { KSelect, KText, KCardFooter, KCardFooterBtn } from '@kasasa/fbase-components';
import { SubType, Type, blockFactory, BlockType } from '@/services/api';
import orderby from 'lodash/orderBy';
import { namespace } from 'vuex-class';

import TypeService from '@/services/TypeService';
import BlockService  from '@/services/BlockService';
import { RouteName } from '@/router';

import { Alert, NoticeClass } from '@kasasa/fbase-components/lib';

import HIRT from '@/components/hirt';

import { SelectOption, mapTypeSubType } from '@/utils/typeSubTypeSelectMap';

const types = namespace('types');

@Component({
	components: {
		KText,
		KSelect,
		KCardFooterBtn,
		KCardFooter,
	},
})
export default class BlockAddNew extends Vue implements HIRT  {
	@Prop({ default: '' }) readonly clientId!: string;
	@Prop({ default: '' }) readonly siteId!: string;

	@types.Action('fetchTypes') fetchTypes!: () => Promise<Type[]>;
	@types.State('types') types !: Type[];

	display = false;
	block = blockFactory();
	
	siteSubTypes = [] as SubType[];

	typeSvc = new TypeService;
	blockSvc = new BlockService(this.$store);

	isMounted = false;
	mounted(): void {
		this.isMounted = true;
	}

	hasErrors(): boolean {
		return this.$v?.$anyError;
	}
	
	isDirty(): boolean {
		return this.$v?.$anyDirty;
	}

	reset(): void {
		this.$v?.$reset();
	}

	touch(): void {
		this.$v?.$touch();
	}

	validations(): RuleDecl {
		return {
			block: {
				name: { required },
				type: { id: {required} },
				subType: { id: {required} },
			}
		};
	}

	get subTypeSelectOptions(): SelectOption[] {
		const subTypesOptons = this.siteSubTypes.filter((el) => el.type === this.block.type.id);
		return orderby(mapTypeSubType(subTypesOptons), [ option => option.text !== 'Default', option => option.text.toLowerCase()]);			
	}

	get typeSelectOptions(): SelectOption[] {	
		return mapTypeSubType(this.types.filter( el => (el.scope === 'site') && (el.id != 'siteCustom')));			
	}

	get showSubTypeSelect(): boolean {
		return (this.subTypeSelectOptions.length > 1);
	}


	@Watch('$route', {immediate: true, deep: true})
	loadSubTypesList(): void {
		this.typeSvc.getSiteSubType(this.clientId, this.siteId)
			.then((resp) => {
				this.siteSubTypes = resp.data.data;					
			});
		this.fetchTypes();
	}

	@Watch('display')
	resetData(): void {
		this.block = blockFactory();
		//reset validation
		this.reset();
	}
	
	get nameErrorMessage(): string | null {
		return this.isMounted ? (
			(this.$v?.block?.name?.$invalid && this.$v?.block?.name?.$dirty) ? 'Name is Required' : null
		) : null;
	}

	get typeErrorMessage(): string | null {		
		return this.isMounted ? (
			(this.$v.block.type?.id?.$invalid && this.$v?.block?.type?.id?.$dirty) ? 'Type is Required' : null
		) : null;
	}

	get subTypeErrorMessage(): string | null {		
		return this.isMounted ? (
			(this.$v.block.subType?.id?.$invalid && this.$v?.block?.subType?.id?.$dirty) ? 'Subtype is Required' : null
		) : null;
	}

		
	saveAction(): Promise<void> | void {
		this.touch();
		if(this.subTypeSelectOptions.length == 1) {
			this.block.subType.id = this.subTypeSelectOptions[0].value.toString();
		}

		if (this.hasErrors()) {
			return;
		}
		const selectedType = this.types.find( x => x.id == this.block.type.id);
		if(selectedType) {
			this.block.type = selectedType;
		}

		const selectedSubType = this.siteSubTypes.find( x => x.id == this.block.subType.id);
		if(selectedSubType) {
			this.block.subType = selectedSubType;
		}


		this.blockSvc.create(this.clientId, this.siteId, this.block)
			.then((resp) => {
				const saved = new Alert(`${this.block.name} is successfully added.`, NoticeClass.SUCCESS);
				saved.setTimeout(6000);
				this.$store.dispatch('notices/add', saved);

				// will send to new BlockPage
				const params = {...this.$route.params, blockId: resp.data.data.id || ''};
				const route = {params: params, name: RouteName.BLOCK_FORM} as RawLocation;
				this.$router.push(route);

			});
	}

	clearSubType(): void {
		this.block.subType.id='';
		this.reset();
	}

}


	
