











import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { namespace } from 'vuex-class';

import { KUnauthorized } from '@kasasa/fbase-components';

import { AuthGroupManager } from '@kasasa/fbase-components/lib';
import BlocksAuthor from '@/store/BlocksAuthor';

const auth = namespace('auth');

@Component({
	components: {
		KUnauthorized
	},
})
export default class Workspace extends Vue {

	@Prop({ default: false }) readonly isGlobal !: boolean;
	@auth.State('authManager') authManager!: AuthGroupManager;

	get auther(): BlocksAuthor {
		return new BlocksAuthor(this.authManager);
	}


	get isAuthorized(): boolean {
		if(this.isGlobal && this.auther.canReadGlobal()) {
			return true;
		} else if(!this.isGlobal && this.auther.canReadClient())  {
			return true;
		}
		return false;		
	}

}
