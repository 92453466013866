import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators';
import { Node } from '@/services/api';


@Module({
	namespaced: true,
})
export default class SelectedNodeStore extends VuexModule {

	//Emtpy Selected Node
	node = {} as Node;

	isLoadingNodeFields = false;

	@Mutation
	setNode(payload: Node): void {
		this.node = payload;
	}

	@Mutation
	setLoadingNodeFields(payload: boolean): void {
		this.isLoadingNodeFields = payload;
	}

	@Action
	setIsLoadingNodeFields(isLoadingNodeFields: boolean): void {
		this.context.commit('setLoadingNodeFields', isLoadingNodeFields);
	}

	@Action
	setSelectedNode(node: boolean): void {
		if (!this.isLoadingNodeFields) {
			this.context.commit('setNode', node);	
		}
	}

	@Action
	unsetSelected(): void {
		this.context.commit('setNode', {});
	}

}