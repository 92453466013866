






















































































import Vue from "vue";
import { Component, Prop, VModel } from 'vue-property-decorator';
import { mapState } from "vuex";

import draggable from "vuedraggable";

import TreeItem from "@/components/NodeTree/TreeItem.vue";
import KMenu from "@/components/Input/KMenu.vue";
import { Block, Node, ExportActionType, ExportActionOption } from "@/services/api";

import { KDropContext } from '@/utils/KDropContext';
import { loadNodeTreeFromLibrary } from '@/utils/NodeHelpers';
import { moveEvent, unchooseEvent } from '@/utils/TreeItemHelpers';


import { namespace } from 'vuex-class';
const selectedNode = namespace('selectedNode');

@Component({
	computed: {
		...mapState(["expanded"]),
	},
	components: {
		draggable,
		TreeItem,
		KMenu,
	}
})
export default class NodeTree extends Vue {
	@VModel() items!: Node[];
	@Prop({ default: false }) ro!: boolean;
	@Prop() clientId !: string;
	@Prop() siteId !: string;
	@Prop() blockId !: string;
	@Prop({ default: false }) readonly isGlobal !: boolean;
	@Prop({ default: false }) readonly isTreeDirty !: boolean;
	@Prop({ default: false }) readonly canExportGlobal !: boolean;


	@selectedNode.Action('unsetSelected') unsetSelected!: () => void;
	@selectedNode.State('node') selectedNode !: Node;

	expanded!: boolean;

	showExportTooltip = false;
	
	get openAll(): boolean {
		return this.expanded;
	}

	set openAll(value: boolean) {
		this.$store.dispatch('setExpanded', value);
	}

	changeEvent(e: Record<string, KDropContext<Block>>): void {

		//change event is called for both added and removed, so checking for added
		if (e.added !== undefined) {	
			const droppedElement = e.added.element;
			
			//check if dropped element is a block from the library
			if(droppedElement.tree != undefined) {
				loadNodeTreeFromLibrary(droppedElement, this.clientId, this.siteId, this.blockId).then(
					(nodeTree) => {
						let rigthSiblings: Node[] = [];
						if( e.added.newIndex < this.items.length){
							rigthSiblings = this.items.slice(e.added.newIndex + 1, this.items.length);
						}
						this.items = this.items.slice(0, e.added.newIndex).concat(nodeTree, rigthSiblings);
					}
				);

			}			
		}	
	}

	get exportActionList(): ExportActionOption[]  {
		const options: ExportActionOption[] = [
			{
				label: 'Custom Site Block',
				type: ExportActionType.customSite,
			}];

		if(this.canExportGlobal){
			options.push({
				label: 'Custom Global Block',
				type: ExportActionType.customGlobal,
			});
		}
		options.push(
			{
				label: 'Pointer',
				type: ExportActionType.pointer,
			});
		return options;
	}

	get showExportBtn(): boolean {
		if(!this.isGlobal && !this.ro && this.selectedNode.uid) {
			return true;
		}
		return false;
	}

	get isExportDisabled(): boolean {
		return this.isTreeDirty;
	}

	moveEvent(e: {dragged:HTMLElement, to: HTMLElement}): boolean {
		return moveEvent(e);
	}

	unchooseEvent(e: {item:HTMLElement}): void{
		return unchooseEvent(e);
	}
}
