import { SubType, Type } from "@/services/api";

export interface SelectOption {
	text: string;
	value: number|string|boolean;
}

export const mapTypeSubType = function (items: SubType[]|Type[]): SelectOption[] {
	const selectOptions = [] as SelectOption[];
	items.forEach((i: SubType| Type) => {
		selectOptions.push({text: i.name, value: i.id});
	});
	return selectOptions;
};

