






import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { KSelectFi } from '@kasasa/fbase-components';

@Component({
	components: {
		KSelectFi
	},
	computed: {}
})
export default class HomePage extends Vue {}
