import Vue from 'vue';
import Vuex from 'vuex';
import { AuthStore, NoticeStore, pendoPlugin } from '@kasasa/fbase-components/lib';
import { Query } from '@/store/modules/types/Query';
import TypeStore from '@/store/modules/TypeStore';
import LibraryStore from '@/store/modules/LibraryStore';
import SelectedNodeStore from '@/store/modules/SelectedNodeStore';

Vue.use(Vuex);

export default new Vuex.Store({
	state: {
		query: new Query(),
		expanded: true,
	},
	mutations: {
		RESET_QUERY(state) {
			state.query = new Query();
		}
	},
	actions: {
		resetQuery(context) {
			context.commit('RESET_QUERY');
		},
		setExpanded(context, payload) {
			context.state.expanded = payload;
		},
	},
	modules: {
		auth: AuthStore,
		notices: NoticeStore,
		types: TypeStore,
		library: LibraryStore,
		selectedNode: SelectedNodeStore,
	},
	plugins: [pendoPlugin]
});
