

































































import { Component, Prop, PropSync } from 'vue-property-decorator';
import Vue from 'vue';
import HIRT from '@/components/hirt';
import { required } from 'vuelidate/lib/validators';
import { RuleDecl } from 'vue/types/options';
import orderby from 'lodash/orderBy';
import { KReadOnly, KSelect, KText } from '@kasasa/fbase-components';

import { Block, SubType, BlockType } from '@/services/api';
import { SelectOption, mapTypeSubType } from '@/utils/typeSubTypeSelectMap';


@Component({
	components: {
		KText,
		KReadOnly,
		KSelect,
	},
})
export default class BlockHeader extends Vue implements HIRT {
	@PropSync('block') localBlock!: Block;
	@Prop({default: false}) readonly ro!: boolean;
	@Prop({default: () => []})  subTypesList!:  SubType[];
	@Prop({default: false}) readonly isBlockDefault!: boolean;
	@Prop({ default: false }) readonly isGlobal !: boolean;

	isMounted = false;
	mounted(): void {
		this.isMounted = true;
	}
	
	validations(): RuleDecl {
		return {
			localBlock: {
				name: { required },
				isDefault: {},
				subType: { id: {required} },
			}
		};
	}
	
	hasErrors(): boolean {
		return this.$v?.$anyError;
	}

	isDirty(): boolean {
		return this.$v?.$anyDirty;
	}

	reset(): void {
		this.$v?.$reset();
	}

	touch(): void {
		this.$v?.$touch();
	}

	get nameErrorMessage(): string | null{
		return this.isMounted ? (
			(this.$v?.localBlock?.name?.$invalid && this.$v?.localBlock?.name?.$dirty) ? 'Name is Required' : null
		) : null;
	}

	get subTypeSelectOptions(): SelectOption[] {
		if	(this.isGlobal) {
			if(this.localBlock.type.id == BlockType.globalCore) {
				return mapTypeSubType(this.subTypesList.filter(el => el.id !== 'default'));
			}
		} else {			
			return orderby(mapTypeSubType(this.subTypesList), [ option => option.text !== 'Default', option => option.text.toLowerCase()]);			
		}
		return [];	
	}

	get showIsDefault(): boolean {
		if (this.isGlobal) {
			return false;
		}

		//If the category selected is 'Default' don't show
		if ( !(this.localBlock.type.id == BlockType.siteLayout) && this.subTypesList.find( (el) => el.id == this.localBlock.subType.id)?.name == 'Default') {
			return false;
		}

		if (this.localBlock.type.id == BlockType.siteContentRegion
		|| this.localBlock.type.id == BlockType.siteGeneralRegion
		|| this.localBlock.type.id == BlockType.siteLayout
		) {
			return true;			
		}
		return false;
	}

	get readOnlyIsDefault(): boolean {
		return this.ro  || this.isBlockDefault;
	}

	get showSubTypeSelect(): boolean {
		return (this.subTypeSelectOptions.length > 1);
	}

	get readOnlySubType(): boolean {

		if (this.isBlockDefault) {
			return true;
		}
		
		//In FI scope the subtype can only be changed for General and Content Region
		if (!this.isGlobal 
			&& this.localBlock.type.id !== BlockType.siteContentRegion
			&& this.localBlock.type.id !== BlockType.siteGeneralRegion
			&& this.localBlock.type.id !== BlockType.sitePointer
		) {
			return true;			
		}
		return this.ro;
	}

	get isDefaultHint(): string {
		if(this.localBlock.isDefault) {
			return 'Default blocks cannot be deleted';
		}
		return '';
	}
}
