import { Type, SubType } from '@/services/api';
import { Node } from '@/services/api';

export interface Block {
	id: string | null;
	type: Type;
	subType: SubType;
	name: string;
	isDefault: boolean;
	tree: Array<Node>;
	uid?: string;
}

export function blockFactory(): Block {
	return {
		id: null,
		name: '',
		type: {
			name: '',
			scope: '',
			id: '',
		},
		subType: {
			name: '',
			type: '',
			id: '',
		},
		isDefault: false,
		tree: [],
	};
}

export const layoutNodesId = ['2', '3', '18', '19'];

export const excludeContentBlocksId = ['3', '18'];

export enum libraryCategories  {
	global = "Custom Global Blocks",
	layout = "Layout Nodes",
	pointer = "Pointer Blocks",
	local = "Custom Site Blocks",
}