import { AuthGroupManager } from "@kasasa/fbase-components/lib";

export default class BlocksAuthor {
	private $mgr: AuthGroupManager;

	constructor(authMgr: AuthGroupManager) {
		this.$mgr = authMgr;
	}

	canReadGlobal(): boolean {
		return this.$mgr.canRead('bones');
	}

	canWriteGlobal(): boolean {
		return this.$mgr.canWrite('bones');
	}

	canWriteClient(): boolean {
		return this.$mgr.canWrite('generalBlocks');
	}

	canReadClient(): boolean {
		return this.$mgr.canRead('generalBlocks');
	}

}