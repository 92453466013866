














































import Vue from 'vue';
import draggable from "vuedraggable";
import { Component, Prop } from 'vue-property-decorator';
import { LibraryItem } from '@/services/api';

import { moveEvent, unchooseEvent } from '@/utils/TreeItemHelpers';

@Component({
	components: {
		draggable,
	},
})
export default class LibraryListGroup extends Vue {
	@Prop({default: () => []}) item!: LibraryItem;
	
	
	moveEvent(e: {dragged:HTMLElement, to: HTMLElement}): boolean {
		return moveEvent(e);
	}

	unchooseEvent(e: {item:HTMLElement}): void{
		return unchooseEvent(e);
	}
}

