























import Vue from "vue";
import { Component, Prop, VModel } from 'vue-property-decorator';

import { KText, KCheckbox, KSelect, KRadioGroup } from '@kasasa/fbase-components';
import NodeFieldTreeSelect from '@/components/Properties/NodeFieldTreeSelect.vue';
import KTextarea from "@/components/Input/KTextarea.vue";

import HIRT from '@/components/hirt';
import { required } from 'vuelidate/lib/validators';
import { RuleDecl } from 'vue/types/options';

import { get, set } from 'lodash';

import { NodeField, NodeFieldOption, Node, nodeFieldRenderComponent, nodeFieldType } from "@/services/api";
import { Validation } from 'vuelidate';
import { ValidationProperties } from 'vue/types/vue';

@Component({
	components: {
		KText,
		KCheckbox,
		KSelect,
		KRadioGroup,
		KTextarea,
		NodeFieldTreeSelect,
	},
})
export default class NodeFieldTemplate extends Vue implements HIRT {
	@Prop({ default: false }) ro !: boolean;
	@Prop() nodeField!: NodeField;
	@VModel() node!: Node;
	
	isMounted = false;

	validations(): RuleDecl {
		const rules:RuleDecl = {} ;
		if(this.nodeField.isRequired) {
			set(rules, this.nodeField.field, {required});
		}
		return { node: rules} ;
	}

	hasErrors(): boolean {
		return this.$v?.$anyError;
	}

	isDirty(): boolean {
		return this.$v?.$anyDirty;
	}

	reset(): void {
		this.$v?.$reset();
	}

	touch(): void {		
		this.$v?.$touch();
	}

	mounted(): void {
		this.isMounted = true;	
	}

	get validationObj(): Validation & ValidationProperties<Node> {
		return get(this.$v, "node." + this.nodeField.field);		
	}

	get errorMessage(): string | null {
		return this.isMounted ? (
			this.validationObj?.$error ? 'Please complete this field.' : null
		) : null;		
	}

	//valueCompare for v-select
	valueCompare (a: number | string| boolean, b:number | string| boolean): boolean {
		return a == b;
	}
	
	//todo fix boolean radio 
	get inputOptions(): NodeFieldOption[] {
		if(typeof get(this.node, this.nodeField.field) == 'boolean') {
			this.nodeField.options.map( (option) => {
				option.value = Boolean(Number(option.value));				
			});
		}
		return this.nodeField.options;
	}

	created():void {
		if ( (this.inputValue === null || this.inputValue === undefined)  && (this.nodeField.type == nodeFieldType.select)) {
			this.inputValue = this.nodeField.options.find((el) => el.isDefault == true)?.id || null ;
		}
	}

	get inputValue(): string | number | boolean | null {
		return get(this.node, this.nodeField.field) as string | number | boolean;
	}

	set inputValue(val: string | number | boolean | null) {
		//null values for unchecked checkboxes need to be converted to false		
		if(typeof get(this.node, this.nodeField.field) == 'boolean') {
			set(this.node, this.nodeField.field, !!val);
		} else {
			set(this.node, this.nodeField.field, val);
		}
	}

	get renderComponent():string {
		return nodeFieldRenderComponent[this.nodeField.type];		
	}

	handleChange(): void {
		this.touch();
		if (this.nodeField.refreshOnChange) { 
			this.$emit('reload'); 
		}
	}

	get disabled(): boolean {
		return this.ro && this.renderComponent == 'k-checkbox';
	}

}
