import { ExpandableFilter } from '@kasasa/fbase-components/lib';
import { uidNode }  from "@/utils/uid";
import { unsetNodeId } from "@/utils/unsetNodeId";
import { Block, Node, BlockType, nodeFactory, NodeType } from "@/services/api";
import BlockService from '@/services/BlockService';
import { v1 as uuid } from "uuid";

//hardcode node type block pointer. 
export const nodeTypeBlockPointer: NodeType = {
	id: '10',
	name: 'Block Pointer',
	canHaveChildren: false,
	packageType: 'BlockPointer',
};

export const createPointerNode = function (droppedBlock: Block): Node {
	const newPointer = nodeFactory();
	newPointer.name = droppedBlock.name;
	newPointer.nodeType = nodeTypeBlockPointer;
	newPointer.custom = { pointerReference: droppedBlock.id };
	return newPointer;
};

export const loadNodeTreeFromLibrary = async function (block: Block, clientId: string | null, siteId: string | null, currentBlockId: string): Promise<Node[]> {
	let nodeTree: Node[] = [];
	
	if (!block.id) {
		return nodeTree;
	}
	const blockSvc = new BlockService;
	let apiCall = null;
	
	switch (block.type.id) {
		case BlockType.siteCustom:
			//Adding check for typescript not to yell about null values. clientId and siteId are null for globalCustom case.
			if (!clientId || !siteId) {
				return nodeTree;
			}
			apiCall = blockSvc.findSiteBlock(clientId, siteId, block.id, (new ExpandableFilter()).expand('tree'));
			break;
		case BlockType.globalCore:			
		case BlockType.globalCustom:
			apiCall = blockSvc.findGlobalBlock(block.id, (new ExpandableFilter()).expand('tree'));
			break;
		case BlockType.sitePointer:
			nodeTree = [createPointerNode(block)];
			uidNode(nodeTree);
			unsetNodeId(nodeTree, currentBlockId);
			return nodeTree;
		default:
			return nodeTree;
	}

	return apiCall.then((resp) => {
		uidNode(resp.data.data.tree);
		unsetNodeId(resp.data.data.tree, currentBlockId);
		if (block.type.id == BlockType.globalCore) {
			return resp.data.data.tree || [];
		} else {
			return resp.data.data.tree[0].children || [];
		}		
	}).catch(() => {
		return [];
	});
};

//recursive function to find the selected node and replace it with the Pointer Node created from the exportedBlock
export const replacePointerBlock = function (exportedBlock: Block, selectedNode: Node, tree: Node): Node {
	if (selectedNode.uid == tree.uid) {
		const newPointerNode = createPointerNode(exportedBlock);
		newPointerNode.uid = uuid();
		newPointerNode.fkBlock = selectedNode.fkBlock;
		return newPointerNode;
	}
	tree.children = tree.children.map((node) => replacePointerBlock(exportedBlock, selectedNode, node));
	return tree;
};