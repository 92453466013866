






































import Vue from 'vue';
import { Component, Watch } from 'vue-property-decorator';
import { mapState } from 'vuex';
import { namespace } from 'vuex-class';
import { AuthGroupManager } from '@kasasa/fbase-components/lib';
import BlocksAuthor from '@/store/BlocksAuthor';

import { KPortalHeader, KAppToolbar, KAppToolbarBtn, KNoticeContainer, KAppToolbarMenu } from '@kasasa/fbase-components';

import BlockAddNew from '@/components/BlockAddNew.vue';

const auth = namespace('auth');

@Component({
	name: 'App',
	components: {
		KPortalHeader, KAppToolbar, KNoticeContainer, KAppToolbarBtn, KAppToolbarMenu, BlockAddNew
	},
	computed: {
		...mapState(['auth'])
	}
})
export default class App extends  Vue {

	@auth.State('authManager') authManager!: AuthGroupManager;

	@Watch('$route', { immediate: true, deep: true })
	loadData(): void {
		if (this.$route.params.siteId) {
			this.$store.dispatch('sites/setSiteIdAction', this.$route.params.siteId);
		}
		if (this.$route.meta.showSelect && this.$route.params.clientId) {
			this.$store.dispatch('sites/setClientId', this.$route.params.clientId);
		}
		if (this.$route.meta.isGlobal) {
			this.$store.dispatch('sites/clearClientId');
		}
	}

	get auther(): BlocksAuthor {
		return new BlocksAuthor(this.authManager);
	}

	chooseSite(siteId: string): void {
		this.$store.dispatch('sites/setSiteIdAction', siteId).then(() => {
			this.$router.push(
				{
					name: 'BlocksList',
					params: {
						clientId: this.$route.params.clientId,
						siteId: this.$store.getters['sites/siteId']
					}
				}
			);
		});
	}

	get showAddNew(): boolean {
		return this.$route.params.siteId && this.$route.meta.showAdd && this.auther.canWriteClient();
	}

	get isWide(): boolean {
		return !!this.$route.meta.wide;
	}

}
