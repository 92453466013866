
























import Vue from "vue";
import { Component, Prop } from 'vue-property-decorator';
import draggable from "vuedraggable";

import { LibraryItem } from '@/services/api';
import LibraryListGroup from '@/components/Library/LibraryListGroup.vue';

@Component({
	components: {
		draggable,
		LibraryListGroup,
	}
})
export default class Library extends Vue {
	@Prop({default: () => []}) items!: LibraryItem[];
	@Prop({ default: false }) ro!: boolean;

}
