export enum BlockType {
	globalCore = 'globalCore',
	globalCustom = 'globalCustom',
	siteContentRegion = 'siteContentRegion',
	siteGeneralRegion = 'siteGeneralRegion',
	siteLayout = 'siteLayout',
	sitePointer = 'sitePointer',
	siteCustom = 'siteCustom',
}

export interface Type {
	id: string;
	name: string;
	scope: string;
}
