
export enum FilterStatus {
	All = "All",
	Default = "Default",
	NotDefault = "Not Default"
}

export class Query {
	siteId = '';
	search = '';
	sortBy = 'name';
	sortDesc = false;
	page = 1;
	perPage = -1;
	useFilter = true;
	selectedType: string[] = [];
	selectedSubType: string[] = [];
	selectedStatus: FilterStatus = FilterStatus.All;
}