import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators';
import { Type, SubType } from '@/services/api';
import TypeService from '@/services/TypeService';

@Module({
	namespaced: true,
})
export default class TypeStore extends VuexModule {

	types: Type[] = [];
	subTypes: SubType[] = [];

	loadingTypes = false;
	loadingSubTypes = false;

	@Mutation
	setTypes(payload: Type[]): void {
		this.types = payload;
	}

	@Mutation
	setSubTypes(payload: SubType[]): void {
		this.subTypes = payload;
	}

	@Mutation
	setLoadingTypes(payload: boolean): void {
		this.loadingTypes = payload;
	}

	@Mutation
	setLoadingSubTypes(payload: boolean): void {
		this.loadingSubTypes = payload;
	}

	@Action( {commit: 'setTypes'} )
	async fetchTypes(): Promise<Type[]> {

		if (this.loadingTypes) {
			return this.types;
		}
		
		if (this.types && this.types.length > 0) {
			return this.types;
		}

		// call service
		const typeSvc = new TypeService;

		this.context.commit('setLoadingTypes', true);
		
		const types: Type[] = await typeSvc.getType()
			.then(resp => {

				this.context.commit('setLoadingTypes', false);
				return resp.data.data || [];
			})
			.finally(() => {

				this.context.commit('setLoadingTypes', false);
				return [];
			});
		
		return types;
	}

	@Action( {commit: 'setSubTypes'} )
	async fetchSubTypes(): Promise<SubType[]> {

		if (this.loadingSubTypes) {
			return this.subTypes;
		}
		
		if (this.subTypes && this.subTypes.length > 0) {
			return this.subTypes;
		}

		// call service
		const typeSvc = new TypeService;

		this.context.commit('setLoadingSubTypes', true);
		
		const subTypes: SubType[] = await typeSvc.getSubType()
			.then(resp => {

				this.context.commit('setLoadingSubTypes', false);
				return resp.data.data || [];
			})
			.finally(() => {

				this.context.commit('setLoadingSubTypes', false);
				return [];
			});
		
		return subTypes;
	}

}