





































































import Vue from 'vue';
import { Component, Prop, VModel } from 'vue-property-decorator';

import { Treeselect as TreeSelect } from '@riophae/vue-treeselect';
import '@riophae/vue-treeselect/dist/vue-treeselect.css';

import { KReadOnly } from '@kasasa/fbase-components';

import { TreeSelectOption, getTextFromTreeOption } from '@/utils/TreeSelectOption';


@Component({
	components: {
		TreeSelect,
		KReadOnly
	},
})
export default class KTreeSelect extends Vue {
	@Prop() items!: TreeSelectOption[];

	@VModel() localValue!: string | number | boolean | null;
	@Prop() label!: string;
	@Prop() readonly!: boolean;
	@Prop() disabled!: boolean;
	@Prop() required!: boolean;
	@Prop() hint!: string;
	@Prop() errorMessages!: string;

	get textFromTreeOption(): string | null {
		
		let displayText = '[No Set]';

		if (!this.items.length) {
			return displayText;
		}
		
		return getTextFromTreeOption(this.items, this.localValue);
	}

	created(): void {		
		if(this.localValue === null) {
			this.localValue = this.findDefaultValue(this.items);
		}
	}
	

	findDefaultValue(items: TreeSelectOption[]) : string | number | boolean | null  {
		let result = null;
		items.some(el => el.isDefault && (result = el.id) || (result = this.findDefaultValue(el.children || [])));
		return result;
	}

}
