import { AxiosResponse } from 'axios';
import { FBaseService, ResponseEnvelopeCollection } from '@kasasa/fbase-components/lib';

// Copied from kcms-location-web
class SiteService extends FBaseService {
	findAll(clientId: string): Promise<AxiosResponse<ResponseEnvelopeCollection<Site>>> {
		return this.apiClient.get(`/api/kcms-site/${clientId}/site`);
	}
}

interface Site {
	id: string;
	fkClient: string;
	name: string;
	title: string;
	enabled: number; // numeric boolean
	isDeleted: number; // numeric boolean
	isDefaultSite: number; // numeric boolean
	domains: Array<Domain>;
}

interface Domain {
	domain: string;
	isDefault: boolean;
	isDeleted: boolean;
}

export {
	Site,
	SiteService
};