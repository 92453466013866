
































































































import Vue from 'vue';
import { Component, Watch, Prop } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { Route, RawLocation } from 'vue-router';

import BlockService  from '@/services/BlockService';
import TypeService from '@/services/TypeService';
import { Block, LibraryItem, libraryCategories, SubType, Node, BlockType, ExportActionOption, ExportActionType } from '@/services/api';

import BlockEditor from '@/components/BlockEditor.vue';
import BlockHeader from '@/components/BlockHeader.vue';
import HIRT from "@/components/hirt";


import { KCardHeader, KSpinner, KCardFooter, KCardHeaderBtn, KCardFooterBtn } from '@kasasa/fbase-components';
import { AuthGroupManager, KCrumb, Alert, NoticeClass, Dialog, NoticeResponse, ExpandableFilter} from '@kasasa/fbase-components/lib';
import { replacePointerBlock } from '@/utils/NodeHelpers';
import { RouteName } from '@/router';
import BlocksAuthor from '@/store/BlocksAuthor';

import { uidNode }  from "@/utils/uid";

const library = namespace('library');
import orderby from 'lodash/orderBy';

const auth = namespace('auth');
const types = namespace('types');
const selectedNode = namespace('selectedNode');

@Component({
	components: {
		KSpinner,
		KCardHeader,
		BlockEditor,
		BlockHeader,
		KCardFooter,
		KCardHeaderBtn,
		KCardFooterBtn,

	}
})
export default class BlockFormPage extends Vue {
	@Prop() clientId !: string;
	@Prop() siteId !: string;
	@Prop() blockId !: string;
	@Prop({ default: false }) readonly isGlobal !: boolean;
	@Prop({ default: 'edit'} ) mode !: string;

	@auth.State('authManager') authManager!: AuthGroupManager;

	@library.Action fetchLibrary!: (forceReload: boolean) => Promise<Block[]>;
	@library.State library !: LibraryItem[];
	@library.State layoutNodes !: LibraryItem[];

	@types.Action('fetchSubTypes') fetchGlobalSubTypes!: () => Promise<SubType[]>;
	@types.State('subTypes') globalSubTypes !: SubType[];

	@selectedNode.Action unsetSelected!: () => void;
	@selectedNode.State('node') selectedNode !: Node;

	siteSubTypes: SubType[] = [];
	typeSvc = new TypeService;

	isLoaded = false;
	overlay = false;

	isBlockDefault = false;

	siteCustomBlocks: Block[] = [];
	pointerBlocks: Block[] = [];

	loadedData = '';

	get libraryItems(): LibraryItem[] {
		if (this.isGlobal) {
			return this.library;
		} else {
			if(this.block.type.id === BlockType.siteLayout) {
				return this.layoutNodes.concat({
					'name': libraryCategories.pointer,
					'blockList': this.pointerBlocks,
				});
			} else {
				return this.library.concat({
					'name': libraryCategories.pointer,
					'blockList': this.pointerBlocks,
				}).concat({
					'name': libraryCategories.local,
					'blockList': this.siteCustomBlocks,
				});
			}
		}
	}

	get auther(): BlocksAuthor {
		return new BlocksAuthor(this.authManager);
	}

	block: Block = {} as Block;
	blockSvc = new BlockService(this.$store);

	async loadLibrary(): Promise<void> {
		return new Promise((resolve, reject) => {
			this.fetchLibrary(true).then( () => {
				if (!this.isGlobal) {
					//load library list for the site
					this.blockSvc.getSiteBlocks(this.clientId, this.siteId).then(resp => resp.data.data)
						.then( (siteBlocks) => {
							const orderedBlocks = orderby(siteBlocks, [ block => block.name.toLowerCase()]);
							//set pointer Blocks
							this.pointerBlocks = orderedBlocks.filter(el => el.type.id === BlockType.sitePointer);
							//set site custom blocks
							this.siteCustomBlocks = orderedBlocks.filter(el => el.type.id === BlockType.siteCustom);
							resolve();
						}).catch( () => {
							reject();
						});
				} else {
					resolve();
				}
			}).catch( () => {
				reject();
			});
		});
	}

	loadSubTypesList(): void {
		if (this.isGlobal) {
			this.fetchGlobalSubTypes();
		} else {
			//load Sub Types for the site selected. 
			this.typeSvc.getSiteSubType(this.clientId, this.siteId)
				.then((resp) => {
					//Filter out by type
					this.siteSubTypes = resp.data.data.filter((el) => el.type === this.block.type.id);					
				});
		}
	}

	get subTypesList(): SubType[] {
		if (this.isGlobal) {
			return this.globalSubTypes;
		} else {
			return this.siteSubTypes;
		}
	}

	@Watch('$route', {immediate: true, deep: true})
	loadBlock(): void {
		
		// load the Block object
		if (this.mode === 'edit') {
			let apiCall = null;
			if (this.isGlobal) {
				apiCall = this.blockSvc.findGlobalBlock(this.blockId, (new ExpandableFilter()).expand('tree'));
			} else {
				apiCall = this.blockSvc.findSiteBlock(this.clientId, this.siteId, this.blockId, (new ExpandableFilter()).expand('tree'));				
			}
			apiCall.then( async (resp)  => {
				this.block = resp.data.data;
				this.isBlockDefault = this.block.isDefault;
				//load blocks for the Library component
				await this.loadLibrary();
				this.loadSubTypesList();
				uidNode(this.block.tree);
				this.setLoadedData(this.block.tree);
				this.isLoaded = true;
			}).catch((e)=> {
				// no need to listen for 401, 403 and 500
				if (e.response.status === 404) {
					this.displayError(`Block "${this.blockId}" is not found. Go back and try again.`);
				}
			});

		}
	}

	setLoadedData(nodes: Node[]): void {
		this.loadedData = JSON.stringify(nodes);
		this.$store.dispatch('setExpanded', true);
	}

	get title(): string {
		return 'Edit';
	}

	get crumbs(): KCrumb[] {
		const crumbs = [];
		crumbs.push(
			{
				key: '0',
				text: 'Blocks',
				disabled: false,
				link: true,
				exact: true,
				to: this.listBlockRoute
			}
		);
		crumbs.push({
			key: '2',
			text: this.title,
			disabled: true
		});
		return crumbs;
	}

	get isTreeDirty(): boolean {
		return this.loadedData !== JSON.stringify(this.block.tree);
	}

	reset(): void {		
		this.setLoadedData(this.block.tree);
		(this.$refs.blockHeader as BlockHeader & HIRT).reset();
	}
	
	get ro(): boolean {
		if	(this.isGlobal) {
			//read-only when type is globalCore or not write permissions 
			return this.block.type.id == BlockType.globalCore || !this.auther.canWriteGlobal();
		} else {
			return !this.auther.canWriteClient();
		}		
	}

	cancelAction(): void {
		// beforeRouteLeave() will detect unsaved changes
		this.goToBlockList();
	}

	goToBlockList(): void {
		this.$router.push(this.listBlockRoute);
	}

	get listBlockRoute(): RawLocation {
		let listBlockRoute = {};
		if (this.isGlobal) {
			listBlockRoute = { name: RouteName.GLOBAL_HOME };
		} else {
			listBlockRoute = { name:  RouteName.BLOCK_LIST, params: { clientId: this.clientId, siteId: this.siteId } };
		}
		return listBlockRoute;
	}


	generateSaveActionPromise(): Promise<void> {
		return new Promise((resolve, reject) => {
			const apiCall = this.isGlobal ? 
				this.blockSvc.updateGlobalBlock(this.blockId, this.block) : this.blockSvc.updateSiteBlock(this.clientId, this.siteId, this.blockId, this.block);

			apiCall.then((resp) => {
				//reload data from API
				this.block = resp.data.data;
				this.postSaveActions();
				resolve();
			})
				.catch((err) => {
					switch (err.response.status) {
						case 400: this.displayError(`"${this.block.name}" failed to save.`);
							break;
						case 404: this.displayError(`Block "${this.block.name}" is not found. Unable to edit.`);
							break;
					}
					reject();
				});
		});
	}

	saveCloseAction(): Promise<void> | void {
		if (this.checkForErrors()) {
			return;
		}

		return this.generateSaveActionPromise().then(() => {
			this.goToBlockList();
		});
	}

	saveAction(): Promise<void> | void {
		if (this.checkForErrors()) {
			return;
		}
		return this.generateSaveActionPromise();
	}

	checkForDirty(): boolean {
		return (this.$refs.blockHeader as BlockHeader & HIRT).isDirty() || this.isTreeDirty;
	}

	postSaveActions(): void {
		const saved = new Alert(`${this.block.name} is successfully saved.`, NoticeClass.SUCCESS);
		saved.setTimeout(6000);
		this.$store.dispatch('notices/add', saved);

		// Setting the loaded status to correctly show/hide the delete button.
		this.isBlockDefault = this.block.isDefault;
		uidNode(this.block.tree);
		this.unsetSelected();
		
		// reset all the vuelidates;
		this.reset();
	}

	displayError(message: string): void {
		const error = new Alert(message, NoticeClass.ERROR);
		error.setTimeout(-1);
		this.$store.dispatch('notices/add', error);
	}	

	checkForErrors(): boolean {
		let errors = false;

		this.blockHeader.touch();
		this.blockEditor.touch();

		errors = this.blockHeader.hasErrors() || this.blockEditor.hasErrors();

		if (errors) {
			const broken = new Alert('Unable to save. Please make sure all required fields are completed without errors.', NoticeClass.ERROR);
			this.$store.dispatch('notices/add', broken);
		}

		return errors;
	}

	get blockEditor(): BlockEditor {
		return this.$refs.blockEditor as BlockEditor;
	}

	get blockHeader(): BlockHeader {
		return this.$refs.blockHeader as BlockHeader;
	}

	// eslint-disable-next-line
	async beforeRouteLeave(to: Route, from: Route, next: Function) {
		if (this.checkForDirty()) {
			const dialog = new Dialog('Unsaved Changes', 'You have unsaved changes on this page that will be lost if you leave now. Are you sure?', 'LEAVE WITHOUT SAVING');
			dialog.setDeclineLabel('STAY ON THIS PAGE')
				.setDismissable(false);

			const res = await this.$store.dispatch('notices/add', dialog);
			switch (res) {
				case NoticeResponse.ACCEPT:
					this.reset();
					next();
					break;
				case NoticeResponse.DECLINE:
				default:
					// staying on the page
					break;
			}
		} else {
			this.reset();
			next();
		}
	}

	get showDelete(): boolean {
		return this.mode === 'edit' && !this.isBlockDefault && !this.ro && !this.isGlobal;
	}

	async deleteBlock(): Promise<void> {
		const dialog = new Dialog(
			'Delete this Block?',
			`Are you sure you want to delete "${this.block.name}"? Caution: this can't be undone.`,
			'DELETE'
		);

		dialog.setDeclineLabel('CANCEL')
			.setDismissable(false);
		const success = new Alert(`${this.block.name} has been deleted.`, NoticeClass.SUCCESS);

		success.setTimeout(6000);

		const res = await this.$store.dispatch('notices/add', dialog);
		switch (res) {
			case NoticeResponse.ACCEPT:
				this.blockSvc.deleteSiteBlock(this.clientId, this.siteId, this.blockId)
					.then(() => {
						this.$store.dispatch('notices/add', success);
						this.goToBlockList();
					})
					.catch((err) => {
						// might be 409, in which case we have a prepared snackbar
						switch (err.response.status) {
							case 409: this.displayError(`"${this.block.name}" could not be deleted because it is being used in one or more places.`);
								break;
							case 404: this.displayError(`Block "${this.block.name}" is not found. Unable to delete`);
								break;
							default:
								this.displayError(`Unable to delete "${this.block.name}"`);
								break;
						}
					});
				break;
			case NoticeResponse.DECLINE:
			default:
				// do nothing
				// the modal closes
				break;
		}
	}

	exportNode(selectedOption: ExportActionOption): void {
		this.overlay = true;
		let apiCall = null;
		switch (selectedOption.type) {
			case ExportActionType.customGlobal:
				apiCall = this.blockSvc.exportGlobalCustomBlock(this.clientId, this.siteId, this.blockId, this.selectedNode);
				break;
			case ExportActionType.customSite:
				apiCall = this.blockSvc.exportSiteCustomBlock(this.clientId, this.siteId, this.blockId, this.selectedNode);
				break;
			case ExportActionType.pointer:
				apiCall = this.blockSvc.exportSitePointerBlock(this.clientId, this.siteId, this.blockId, this.selectedNode);
				break;

		}
		apiCall.then( (resp) => 
		{
			this.loadLibrary().then(() => {
				const alert = new Alert(`${this.selectedNode.name} has been successfully exported.`, NoticeClass.SUCCESS);
				alert.setTimeout(6000);
				this.$store.dispatch('notices/add', alert);
				this.overlay = false;
			});
			if(selectedOption.type === ExportActionType.pointer) {				
				replacePointerBlock(resp.data.data, this.selectedNode, this.block.tree[0]);				
			}
			
		});
	}

	get canExportGlobal(): boolean {
		return this.auther.canWriteGlobal();
	}

	get showDuplicateBtn(): boolean {
		return !this.isGlobal && !this.ro && this.block.type.id != BlockType.siteCustom && this.mode === 'edit';
	}

	duplicateBlock(): void {
		this.overlay = true;
		this.blockSvc.duplicate(this.clientId, this.siteId,  this.blockId)
			.then((resp) => {

				const success = new Alert(`${this.block.name} has been successfully duplicated.`, NoticeClass.SUCCESS);
				success.setTimeout(6000);
				this.$store.dispatch('notices/add', success);
				this.$router.push(
					{ 	name:  RouteName.BLOCK_FORM, 
						params: { 
							clientId: this.clientId, 
							siteId: this.siteId,  
							blockId: resp.data.data.id || ''
						}
					});

				
			})
			.catch(() => {
				// might be 409, in which case we have a prepared snackbar
				const fail = new Alert(`There was an error duplicating ${this.block.name}.`, NoticeClass.ERROR);
				fail.setTimeout(6000);
				this.$store.dispatch('notices/add', fail);
			})
			.finally(() => {
				this.overlay = false;
			});
	}
}

