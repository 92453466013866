import Vue from 'vue';
import VueRouter, {Route} from 'vue-router';
import {Dictionary} from 'vue-router/types/router';
import HomePage from '@/pages/HomePage.vue';
import FiHome from '@/pages/FiHome.vue';
import ListBlocksPage from '@/pages/ListBlocksPage.vue';
import BlockFormPage from '@/pages/BlockFormPage.vue';
import Workspace from '@/components/Workspace.vue';

import {GlobalWorkspaceRoutes, NonGlobalWorkspaceRoutes} from '@kasasa/fbase-components/lib';

const mergeMeta = (route: Route): Dictionary<string> => {
	return {...route.meta, ...route.params};
};

Vue.use(VueRouter);

export const RouteName = {
	GLOBAL_HOME: 'GlobalHome',
	GLOBAL_EDIT: 'GlobalBlockForm',
	HOME: 'Home',
	BLOCK_LIST: 'BlocksList',
	FI_HOME: 'FiHome',
	BLOCK_FORM: 'BlockForm',
};


const routes = [
	GlobalWorkspaceRoutes([
		{
			path: '/global',
			component: Workspace,
			meta: {
				showSelect: false,
				isGlobal: true,
			},
			props: mergeMeta,
			children: [
				{
					path: '',
					component: ListBlocksPage,
					name: RouteName.GLOBAL_HOME,
					props: mergeMeta,
					meta: {
						isGlobal: true,
					},
				},
				{
					path: ':blockId',
					name: RouteName.GLOBAL_EDIT,
					component: BlockFormPage,
					props: mergeMeta,
					meta: {
						mode: 'edit',
						isGlobal: true,
						wide: true,
					}
				},
			],
		}
	]),
	NonGlobalWorkspaceRoutes([
		{
			path: '/',
			component: Workspace,
			props: mergeMeta,
			meta: {
				isGlobal: false,
			},
			children: [
				{
					path: '',
					name: RouteName.HOME,
					component: HomePage,
				}
			],
		},
		{
			path: '/:clientId',
			component: Workspace,
			props: mergeMeta,
			meta: {
				isGlobal: false,
			},
			children: [
				{
					path: '',
					name: RouteName.FI_HOME,
					component: FiHome,
					props: mergeMeta,
					meta: {
						showSelect: true,
						isGlobal: false,
					}
				},
				{
					path: 'site/:siteId',
					name: RouteName.BLOCK_LIST,
					component: ListBlocksPage,
					props: mergeMeta,
					meta: {
						showSelect: true,
						showAdd: true,
						isGlobal: false,
					}
				},
				{
					path: 'site/:siteId/block/:blockId',
					name: RouteName.BLOCK_FORM,
					component: BlockFormPage,
					props: mergeMeta,
					meta: {
						mode: 'edit',
						showSelect: true,
						isGlobal: false,
						wide: true,
					}
				},
			],
		},
		{
			path: '*',
			redirect: {name: RouteName.GLOBAL_HOME}
		}
	]),
];

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes
});

export default router;
