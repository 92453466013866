export interface TreeSelectOption {
	id: string | number;
	isDisabled: boolean;
	label: string;
	isDefault: boolean;
	children?: TreeSelectOption[] | false;
}

import { ListOfRecursiveArraysOrValues, flatMapDeep } from 'lodash';


const flatten = function (item: TreeSelectOption): ListOfRecursiveArraysOrValues<TreeSelectOption> {
	if (item.children) {
		return [item, flatMapDeep(item.children, flatten)];
	} else {
		return [item];
	}	 
};

export const getTextFromTreeOption = function (options: TreeSelectOption[], value: string | number | boolean | null): string | null {
	//todo improve function to check form multiple=true and return the text for the multiple options selected. 
	const result = flatMapDeep(options, flatten).find(el => el.id == value);	
	return result ? result.label : null;
};