






import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import SelectSite from '@/components/SelectSite.vue';

@Component({
	name: "siteSelection",
	components: {
		SelectSite
	},
	computed: {}
})
export default class FiHome extends Vue {
}
