export interface NodeType {
	id: string | null;
	name: string;
	canHaveChildren: boolean;
	packageType: string;
}
export interface Node {
	id: string | null;
	parentId: number | string | null;
	fkBlock: string | null;
	nodeType: NodeType;
	enabled: boolean;
	isDeprecated: boolean;
	name: string | null;
	children: Array<Node>;
	custom?: Record<string, string | number | null>;
	weight?: number;
	uid?: string;
}

export enum nodeFieldType  {
	input = "input",
	checkbox = "checkbox",
	select = "select",
	treeselect = "treeselect",
	radio = "radio",
	textarea = "textarea",
}

export const nodeFieldRenderComponent: Record <string, string> = {
	[nodeFieldType.input]: 'k-text',
	[nodeFieldType.checkbox]: 'k-checkbox',
	[nodeFieldType.select]: 'k-select',
	[nodeFieldType.treeselect]: 'node-field-tree-select',
	[nodeFieldType.radio]: 'k-radio-group',
	[nodeFieldType.textarea]: 'k-textarea',
};
export interface NodeFieldOption {	
	label: string;
	value: number | boolean| string;	
	selectable: boolean;
	children?: NodeFieldOption[];	
	text: string;
	id: string;
	isDisabled: boolean;
	isDefault: boolean;
}

export interface NodeField {
	type: nodeFieldType;
	field: string;
	label: string;
	hint: string;
	refreshOnChange: boolean;
	isRequired: boolean;
	options: NodeFieldOption[];
}



export function nodeFactory(): Node {
	return {
		id: null,
		parentId: null,
		fkBlock: null,
		nodeType: {
			id: null,
			name: '',
			canHaveChildren: false,
			packageType: '',
		},
		enabled: true,
		isDeprecated: false,
		name: '',
		weight: 0,
		children: [],
	};
}

export enum ExportActionType {
	customGlobal = "customGlobal",
	customSite = "customSite",
	pointer = "pointer",
}
export interface ExportActionOption {
	label: string;
	type: ExportActionType;
}
