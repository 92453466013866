import { AxiosResponse } from 'axios';
import { FBaseService, ResponseEnvelopeCollection } from '@kasasa/fbase-components/lib'; 
import { Type, SubType } from '@/services/api';

export default class TypeService extends FBaseService {


	getType(): Promise<AxiosResponse<ResponseEnvelopeCollection<Type>>> {
		return this.apiClient.get(`/api/kcms-block/type`);
	}

	getSubType(): Promise<AxiosResponse<ResponseEnvelopeCollection<SubType>>> {
		return this.apiClient.get(`/api/kcms-block/sub-type`);
	}

	getSiteSubType(clientId: string, siteId: string):  Promise<AxiosResponse<ResponseEnvelopeCollection<SubType>>> {
		return this.apiClient.get(`/api/kcms-block/${clientId}/site/${siteId}/sub-type`);
	}

	protected async handleForbidden(): Promise<void> {
		// fail silently
		return;
	}

	protected async handleUnauthenticated(): Promise<void> {
		// fail silently
		return;
	}

	protected async hanldeServerError(): Promise<void> {
		// fail silently
		return;
	}
}
