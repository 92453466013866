





















































import Vue from "vue";
import { Component, Prop, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
const selectedNode = namespace('selectedNode');
import BlockService  from '@/services/BlockService';
import NodeFieldTemplate from "@/components/Properties/NodeFieldTemplate.vue";
import { KSpinner } from '@kasasa/fbase-components';

import { Node, NodeField, Block, BlockType, NodeFieldOption } from "@/services/api";

import HIRT from '@/components/hirt';

import { v1 as uuid } from "uuid";

@Component({
	components: {
		NodeFieldTemplate,
		KSpinner,
	},
})
export default class Properties extends Vue implements HIRT {
	@Prop({ default: false }) ro !: boolean;
	@Prop() block!: Block;
	@Prop() clientId !: string;
	@Prop() siteId !: string;
	@Prop({ default: false }) readonly isGlobal !: boolean;

	nodeFields : NodeField[] = [];

	blockSvc = new BlockService(this.$store);

	@selectedNode.State node !: Node;
	@selectedNode.State isLoadingNodeFields !: boolean;
	@selectedNode.Action setIsLoadingNodeFields!: (isLoadingNodeFields: boolean) => void;
	
	get refNodeFields() :  NodeFieldTemplate[] {
		if(this.$refs.nodeFields){
			return this.$refs.nodeFields as NodeFieldTemplate[];
		}
		return [];
	}

	hasErrors(): boolean {
		let hasErrors = false;
		this.refNodeFields.forEach(
			(el) => {
				if(el.hasErrors()) {
					hasErrors = true;
				}
			}
		);
		return hasErrors;
	}

	isDirty(): boolean {
		let isDirty = false;
		this.refNodeFields.forEach(
			(el) => {
				if(el.isDirty()) {
					isDirty = true;
				}
			}
		);
		return isDirty;
	}

	reset(): void {
		this.refNodeFields.forEach(
			(el) => {
				el.reset();
			}
		);
	}

	touch(): void {
		this.refNodeFields.forEach(
			(el) => {
				el.touch();
			}
		);
	}

	get nodeName(): string {
		return this.node.name ? ": " + this.node.name : "";		
	}

	get isNodeSelected(): boolean {
		return this.node.name != undefined;
	}

	get showDeleteForNode(): boolean {
		if(this.ro){
			return false;
		}
		if(this.isGlobal && this.block.type.id != BlockType.globalCustom) {
			return false;
		}
		return true;
	}


	@Watch('node')
	loadNodeFields(_node: Node, refreshEvent = false): void {
		if(!this.isNodeSelected) {
			this.nodeFields = [];
			return;
		}
		let apiCall = null;
		if(this.isGlobal) {
			apiCall = this.blockSvc.getGlobalNodeFields(this.node);
		} else {
			apiCall = this.blockSvc.getSiteNodeFields(this.clientId, this.siteId, this.node);
		}
		
		this.setIsLoadingNodeFields(true);
		apiCall.then(
			(resp) => {
				resp.data.data.map(
					(nodeField) => {
						nodeField.options = this.mapNodeFieldOptions(nodeField.options);
					});
				this.nodeFields = resp.data.data;
				this.setIsLoadingNodeFields(false);
				if (refreshEvent === true) {
					this.$nextTick(() => {
						this.touch();
					});
				}
			}
		);
	}

	mapNodeFieldOptions(nodeFieldOptions: NodeFieldOption[]): NodeFieldOption[] {
		nodeFieldOptions.map( 
			(option) => { 
				option.text = option.label;				
				//id for treeselect should not be empty for not selectable option when the option.value is undefined or empty
				if(!option.selectable && (option.value == undefined || option.value == '')) {
					option.id = uuid();
				} else {
					option.id = option.value.toString();
				}
				option.isDisabled = !option.selectable;	
				if	(option.children && option.children.length > 0 ) {
					option.children = this.mapNodeFieldOptions(option.children);			
				} else {
					delete(option.children);
				}
			});
		return nodeFieldOptions;
	}
}
