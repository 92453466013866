import { Node } from "@/services/api";
//unset the node.id and node.parentId.  fkBlock should be point to the current blockId
export const unsetNodeId = function (items: Node[], currentBlockId: string): void {
	items.forEach((i) => {
		i.id = null;
		i.parentId = null;
		i.fkBlock = currentBlockId;
		unsetNodeId(i.children, currentBlockId);
	});
};
