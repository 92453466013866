














import Vue from 'vue';
import { Component, Watch, Prop } from 'vue-property-decorator';
import { KSpinner } from '@kasasa/fbase-components';

import BlockListTable from '@/components/BlockListTable.vue';
import BlockService from '@/services/BlockService';
import { Block } from '@/services/api';

import { namespace } from 'vuex-class';
const library = namespace('library');

@Component({
	components: {
		KSpinner,
		BlockListTable
	}
})
export default class ListBlocksPage extends Vue {
	@Prop() clientId !: string;
	@Prop() siteId !: string;

	@library.Action('fetchLibrary') fetchLibrary!: (forceReload: boolean) => Promise<Block[]>;
	@library.State('globalBlocks') globalBlocks !: Block[];
	@Prop({ default: false }) readonly isGlobal !: boolean;

	status: 'loading' | 'loaded' | 'empty' = 'loading';

	blocks: Block[] = [];
	blockSvc = new BlockService(this.$store);

	@Watch('$route', {immediate: true, deep: true})
	loadNavs(): void {
		this.status = 'loading';
		this.blocks = [];
		
		if (this.isGlobal) {
			this.fetchLibrary(true).then(
				() => {
					this.blocks = this.globalBlocks;
					this.status = (this.blocks.length ? 'loaded' : 'empty');
				}
			);
		} else {
			this.blockSvc.getSiteBlocks(this.clientId, this.siteId)
				.then((resp) => {
					this.blocks = resp.data.data;
					this.status = (this.blocks.length ? 'loaded' : 'empty');
				});
		}
	}

}
