var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('li',{class:[ _vm.isSelected? 'active': '']},[(_vm.isLoading)?_c('k-spinner',{staticClass:"item",attrs:{"height":"37px","size":"25","width":"3"}}):[_c('div',{class:[
				'item',
				'item-node-depth-' + _vm.depth,
				_vm.isFolder ? 'folder-node-depth-' + _vm.depth: '',
				!_vm.ro ? 'handle' : '' ],on:{"click":_vm.select}},[(_vm.isFolder)?_c('span',{staticClass:"toggle mx-4",on:{"click":function($event){_vm.isOpen = !_vm.isOpen}}},[_c('v-icon',{class:{ caretOpen: _vm.isFolderOpen },domProps:{"textContent":_vm._s('fas fa-caret-right')}})],1):_c('span',[_c('v-icon',{staticClass:"text-px-8 pl-4 pr-6",domProps:{"textContent":_vm._s(_vm.depthIndicationIcon)}})],1),_c('span',{class:{
					disabled: _vm.isDisabled,
					'font-weight-bold': _vm.isRootNode,						
				}},[_vm._v(" "+_vm._s(_vm.item.name? _vm.item.name : '[Not Set]')+" ")]),_c('span',{class:{										
					disabled: _vm.isDisabled,
					'sub-type': true
				}},[_vm._v(" "+_vm._s(_vm.item.nodeType.name)+" ")])]),(_vm.isFolder)?_c('ul',{class:['branchItem', 'item-list', _vm.isFolderOpen ? 'expanded' : ''],attrs:{"data-depth":_vm.depth+1}},[_c('draggable',{attrs:{"disabled":_vm.ro,"list":_vm.item.children,"group":{ name: 'nodetree', put: ['library', 'nodetree'] },"ghost-class":"chosen-item","chosen-class":"chosen-item","animation":"200","move":_vm.moveEvent},on:{"change":_vm.changeEvent,"unchoose":_vm.unchooseEvent}},_vm._l((_vm.item.children),function(child){return _c('tree-item',{directives:[{name:"show",rawName:"v-show",value:(_vm.isFolderOpen),expression:"isFolderOpen"}],key:child.uid,attrs:{"item":child,"depth":_vm.depth + 1,"ro":_vm.ro,"parent-enabled":!_vm.isDisabled,"client-id":_vm.clientId,"site-id":_vm.siteId,"block-id":_vm.blockId}})}),1)],1):_vm._e()]],2)}
var staticRenderFns = []

export { render, staticRenderFns }