















































import Vue from 'vue';
import { Component, Prop, VModel } from 'vue-property-decorator';
import { KReadOnly } from '@kasasa/fbase-components';


@Component({
	components: {
		KReadOnly
	},
})
export default class KTextarea extends Vue {
	//todo  fields will be clear once moved to fbase-component and extends from KInputBase
	@VModel() localValue!: string | number | boolean;
	@Prop() label!: string;
	@Prop() readonly!: boolean;
	@Prop() disabled!: boolean;
	@Prop() required!: boolean;
	
}
