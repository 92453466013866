



































import Vue from 'vue';
import { Component, Prop, VModel, Watch } from 'vue-property-decorator';

import Library from "@/components/Library/Library.vue";
import NodeTree from "@/components/NodeTree/NodeTree.vue";
import Properties from "@/components/Properties/Properties.vue";
import HIRT from '@/components/hirt';

import { namespace } from 'vuex-class';
const selectedNode = namespace('selectedNode');

import { removeNode }  from "@/utils/removeNode";


import { Block, LibraryItem, Node, BlockType } from '@/services/api';

@Component({
	components: {
		Library,
		NodeTree,
		Properties,
	},
})
export default class BlockEditor extends Vue implements HIRT {
	@Prop({default: false}) readonly ro!: boolean;
	@VModel() block!: Block;
	@Prop({ default: () => []}) readonly libraryItems !: LibraryItem[];
	@Prop() clientId !: string;
	@Prop() siteId !: string;
	@Prop({ default: false }) readonly isGlobal !: boolean;
	@Prop({ default: false }) readonly isTreeDirty !: boolean;
	@Prop({ default: false }) readonly canExportGlobal !: boolean;

	@selectedNode.Action('unsetSelected') unsetSelected!: () => void;
	

	hasErrors(): boolean {
		return this.properties.hasErrors();		
	}

	isDirty(): boolean {
		return this.properties.isDirty();		
	}

	reset(): void {
		this.properties.reset();
	}

	touch(): void {		
		this.properties.touch();
	}

	get properties(): Properties {
		return (this.$refs.properties as Properties);
	}
	//when leaving the block view, unselect
	@Watch('$route', { immediate: true, deep: true })
	onRouteChange(): void {		
		this.unsetSelected();
	}

	get children(): Node[] {
		//for globalCore the node tree is just the root parent
		if(this.block.type.id == BlockType.globalCore) {
			return this.block.tree;
		}
		return this.tree.children;
	}

	set children(newValue: Node[]) {
		//set should not happen for globalCore blocks
		if(this.block.type.id != BlockType.globalCore) {
			this.tree.children = newValue;
		}
	}

	get tree(): Node {
		return this.block.tree[0];
	}

	set tree(newValue: Node) {
		this.block.tree[0] = newValue;
	}

	remove(uid: string): void {
		this.tree = removeNode(this.tree, uid);
		this.unsetSelected();
	}
}

