










































/**
 * Custom Select Control
 * 
 * Select control which handles 'Select All' option and chips.
 * 
 * @desc Select control which handles Select All feature, and chips
 * @author James Hester <james.hester@kasasa.com>
 * @date 08/10/20
 * @todo Refactor to component library, incorporate into other places
 **/
import { Component, Prop, PropSync, Vue } from 'vue-property-decorator';

@Component({
	components: {}
})
export default class SelectFilter extends Vue {

	@Prop() label!: string;

	@Prop({default: []}) items!: string[];

	@PropSync('value', {default: []}) selected!: string[];

	@Prop({default: []}) value!: string[];

	numberOfChips = 2;

	onClickSelectAll(): void {
		(this.hasAnySelected ) ? this.deselectAll() : this.selectAll();
	}

	selectAll(): void {
		this.selected = this.items;
	}

	deselectAll(): void {
		this.selected = [];
	}

	created(): void {
		this.$on('update:value', this.onChange);
	}

	onChange(val: string[]): void {
		this.$emit('input', val);
	}

	get hasPartialSelected(): boolean { return this.hasAnySelected && !this.hasAllSelected; }

	get hasAllSelected(): boolean { return this.selected.length == this.items.length; }

	get hasNoneSelected(): boolean { return this.selected.length == 0; }

	get hasAnySelected(): boolean { return !this.hasNoneSelected; }

	get selectAllIcon(): string {
		if (this.hasAllSelected) return 'fas fa-check-square';
		if (this.hasPartialSelected) return 'fas fa-minus-square';
		return 'far fa-square';
	}

	get selectAllColor(): string { return (this.hasAnySelected) ? 'indigo darken-4' : 'grey'; }
}
