import { v1 as uuid } from "uuid";
import { Block, Node } from "@/services/api";

export const uid = function (items: Block[]): void {
	items.forEach((i) => {
		i.uid = uuid();
	});
};

export const uidNode = function (items: Node[]): void {
	items.forEach((i) => {
		i.uid = uuid();
		uidNode(i.children);
	});
};
