import { AxiosResponse } from 'axios';
import { FBaseService, ResponseEnvelope, ResponseEnvelopeCollection, ExpandableFilter } from '@kasasa/fbase-components/lib'; 
import { Block, Node, NodeField } from '@/services/api';

export default class BlockService extends FBaseService {

	getGlobalBlocks(): Promise<AxiosResponse<ResponseEnvelopeCollection<Block>>> {
		return this.apiClient.get(`/api/kcms-block/global/block`);
	}

	getSiteBlocks(clientId: string, siteId: string): Promise<AxiosResponse<ResponseEnvelopeCollection<Block>>> {
		return this.apiClient.get(`/api/kcms-block/${clientId}/site/${siteId}/block`);
	}

	findGlobalBlock(id: string, filter: ExpandableFilter): Promise<AxiosResponse<ResponseEnvelope<Block>>> {
		return this.apiClient.get(`/api/kcms-block/global/block/${id}`, { params: filter.getQuery() });		
	}
	
	findSiteBlock(clientId: string, siteId: string, id: string, filter: ExpandableFilter): Promise<AxiosResponse<ResponseEnvelope<Block>>> {
		return this.apiClient.get(`/api/kcms-block/${clientId}/site/${siteId}/block/${id}`, { params: filter.getQuery() });
	}

	create(clientId: string, siteId: string, block: Block): Promise<AxiosResponse<ResponseEnvelope<Block>>> {
		return this.apiClient.post(`/api/kcms-block/${clientId}/site/${siteId}/block`, block);
	}

	updateGlobalBlock(id: string, block: Block): Promise<AxiosResponse<ResponseEnvelope<Block>>> {
		return this.apiClient.put(`/api/kcms-block/global/block/${id}`, block);
	}

	updateSiteBlock(clientId: string, siteId: string, id: string, block: Block): Promise<AxiosResponse<ResponseEnvelope<Block>>> {
		return this.apiClient.put(`/api/kcms-block/${clientId}/site/${siteId}/block/${id}`, block);
	}

	deleteSiteBlock(clientId: string, siteId: string, id: string): Promise<AxiosResponse<ResponseEnvelope<unknown>>> {
		return this.apiClient.delete(`/api/kcms-block/${clientId}/site/${siteId}/block/${id}`);
	}

	getSiteNodeFields(clientId: string, siteId: string, node: Node): Promise<AxiosResponse<ResponseEnvelopeCollection<NodeField>>> {
		return this.apiClient.post(`/api/kcms-block/${clientId}/site/${siteId}/node-fields`, node);
	}

	getGlobalNodeFields(node: Node): Promise<AxiosResponse<ResponseEnvelopeCollection<NodeField>>> {
		return this.apiClient.post(`/api/kcms-block/global/node-fields`, node);
	}

	exportGlobalCustomBlock(clientId: string, siteId: string, blockId: string, node: Node): Promise<AxiosResponse<ResponseEnvelope<Block>>> {
		return this.apiClient.post(`/api/kcms-block/${clientId}/site/${siteId}/block/${blockId}/node/${node.id}/export-global-custom-job`, node);
	}

	exportSitePointerBlock(clientId: string, siteId: string, blockId: string, node: Node): Promise<AxiosResponse<ResponseEnvelope<Block>>> {
		return this.apiClient.post(`/api/kcms-block/${clientId}/site/${siteId}/block/${blockId}/node/${node.id}/export-site-pointer-job`, node);
	}

	exportSiteCustomBlock(clientId: string, siteId: string, blockId: string, node: Node): Promise<AxiosResponse<ResponseEnvelope<Block>>> {
		return this.apiClient.post(`/api/kcms-block/${clientId}/site/${siteId}/block/${blockId}/node/${node.id}/export-site-custom-job`, node);
	}

	duplicate(clientId: string, siteId: string, blockId: string): Promise<AxiosResponse<ResponseEnvelope<Block>>> {
		return this.apiClient.post(`/api/kcms-block/${clientId}/site/${siteId}/block/${blockId}/duplicate-job`, {});
	}

}