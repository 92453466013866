export const moveEvent = function (e: { dragged: HTMLElement, to: HTMLElement }): boolean {
	let depth = e.to.parentElement?.dataset.depth;
	if (depth == undefined) {
		depth = '0';
	}
	e.dragged.className = "item-dragged item-depth-" + depth;
	return true;
};

export const unchooseEvent = function (e: { item: HTMLElement }): void {
	e.item.className = '';
};