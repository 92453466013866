


























































































import Vue from "vue";
import { Component, Prop, Watch } from 'vue-property-decorator';
import { mapState } from "vuex";
import { Block, Node } from "@/services/api";
import draggable from "vuedraggable";
import { KDropContext } from '@/utils/KDropContext';
import { loadNodeTreeFromLibrary } from '@/utils/NodeHelpers';
import { moveEvent, unchooseEvent } from '@/utils/TreeItemHelpers';
import { KSpinner } from '@kasasa/fbase-components';

import { namespace } from 'vuex-class';
const selectedNode = namespace('selectedNode');



@Component({
	name: 'TreeItem',
	computed: {
		...mapState(["expanded"])
	},
	components: {
		draggable,
		KSpinner
	}
})
export default class TreeItem extends Vue {
	@Prop() item!: Node;
	@Prop({default: 0}) depth!: number;
	@Prop({ default: false }) ro!: boolean;
	@Prop({ default: true }) parentEnabled!: boolean;
	@Prop() clientId !: string;
	@Prop() siteId !: string;
	@Prop() blockId !: string;

	@selectedNode.Action('setSelectedNode') setSelectedNode!: (node: Node) => void;
	@selectedNode.State('node') selectedNode !: Node;

	expanded!: boolean;

	isOpen = true;

	@Watch('expanded')
	onExpandedChange(val: boolean): void {
		this.isOpen = val;
	}


	get depthIndicationIcon(): string {
		return this.depth % 2 === 0 ? 'far fa-circle' : 'fas fa-circle';
	}

	get isFolder(): boolean {
		return this.item.nodeType.canHaveChildren;
	}

	get isFolderOpen(): boolean {
		return this.isOpen && this.item.nodeType.canHaveChildren;
	}

	get isRootNode(): boolean {
		return this.depth == 0;
	}

	get isSelected(): boolean {
		return this.selectedNode.uid != undefined  && this.selectedNode.uid === this.item.uid;
	}

	get isDisabled(): boolean {
		return !this.item.enabled || !this.parentEnabled;
	}

	select(): void {
		this.setSelectedNode(this.item);
	}

	


	changeEvent(e: Record<string, KDropContext<Block>>): void {

		//change event is called for both added and removed, so checking for added
		if (e.added !== undefined) {	
			const droppedElement = e.added.element;
			
			//check if dropped element is a block from the library
			if(droppedElement.tree != undefined) {
				loadNodeTreeFromLibrary(droppedElement, this.clientId, this.siteId, this.blockId).then(
					(nodeTree) => {
						let rigthSiblings: Node[] = [];
						if( e.added.newIndex < this.item.children.length){
							rigthSiblings = this.item.children.slice(e.added.newIndex + 1, this.item.children.length);
						}
						this.item.children = this.item.children.slice(0, e.added.newIndex).concat(nodeTree, rigthSiblings);
					}
				);

			}			
		}	
	}

	get isLoading(): boolean {
		if(this.item.nodeType == undefined) {
			return true;
		}
		return false;
	}

	moveEvent(e: {dragged:HTMLElement, to: HTMLElement}): boolean {
		return moveEvent(e);
	}

	unchooseEvent(e: {item:HTMLElement}): void{
		return unchooseEvent(e);
	}

}

