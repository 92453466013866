



















import Vue from 'vue';
import { Component, VModel } from 'vue-property-decorator';


import KTreeSelect from '@/components/Input/KTreeSelect.vue';


@Component({
	components: {
		KTreeSelect,
	},
})
export default class NodeFieldTreeSelect extends Vue {
		@VModel() localValue!: string | number | boolean;

}
